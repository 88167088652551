import React from "react";
import { TextPassage} from "@exxonmobil/react-unity";
import Attestation from "pages/Attestation";

const Home = () => {

  return (
    <section>
      <TextPassage>
        <h2>Instructions</h2>
        <TextPassage.Intro style={{textAlign: "justify"}}>
        Please use the tabs below to review the items under your ownership and follow the instructions in each tab to confirm your ownership of items on each respective platform. <br/><br/>You should have received an email for each platform that you own an item(s) on.
        </TextPassage.Intro>
      </TextPassage>
      <Attestation/>
    </section>
  );
};

export default Home;
