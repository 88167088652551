import { React, useEffect, useState, useRef, } from "react";
import { Button, Tabs, Alert, ProgressBar } from "@exxonmobil/react-unity";
import { useLocation } from "react-router-dom";
import { authProvider, authenticationParameters } from "../../authProvider.js";
import ESTransferModal from "components/TransferModal/esTransferModal.js";
import ApimTransferModal from "components/TransferModal/apimTransferModal.js";
import MulesoftTransferModal from "components/TransferModal/mulesoftTransferModal.js";
import NativeTransferModal from "components/TransferModal/nativeTransferModal.js"
import ObjectCkboxList from "components/ObjectCkboxList";
import { AttestationAPIService } from "services/attestationAPIService";
import { MSAPIService } from "services/msAPIService";
import { getMsalAuthToken } from "../../MsalAuthToken";

export default function Attestation() {
    const PLATFORM_TYPE = {
        APIM: "apmi",
        EVENT_STREAMING: "eventstreaming",
        MULESOFT: "mulesoft",
        NATIVE_API: "NATIVEAPI"
    }
    const location = useLocation();
    const userInfo = useRef({
        displayName: null,
        email: null,
        groupInfoAPIM: null,
        groupInfoMulesoft: null,
        groupInfoEvenstreaming: null,
        groupInfoNativeAPI: null,
        apimObjsByGroup: null,
        muleSoftObjsByGroup: null,
        eventstreamingObjsByGroup: null,
        nativeObjsByGroup: null,
        errorMessage: null,
        isUserNotFound: false,
        msService: null,
        attestationAPIService: null
    });

    const [backgroundState, setBackgroundState] = useState({
        isLoading: true,
        errorMessage: null,
        isUserNotFound: false,
        isShowAPIMTransferModal: false,
        isShowESTransferModal: false,
        isShowMulesoftTransferModal: false,
        isShowNativeTransferModal: false

    });

    const [userInfoState, setUserInfo] = useState({
        displayName: null,
        email: null,
        groupInfoAPIM: null,
        groupInfoMulesoft: null,
        groupInfoEvenstreaming: null,
        groupInfoNativeAPI: null,
        apimObjsByGroup: {},
        muleSoftObjsByGroup: {},
        eventstreamingObjsByGroup: {},
        nativeObjsByGroup: {},
    });

    const [apimObjListCheckboxState, setApimObjListCheckboxState] = useState({
        checkboxState: {},
    });

    const [muleObjListsoftCheckboxState, setMuleObjListsoftCheckboxState] = useState({
        checkboxState: {}
    });

    const [esObjListCheckboxState, setEsObjListCheckboxState] = useState({
        checkboxState: {}
    });

    const [nativeObjListCheckboxState, setNativeObjListCheckboxState] = useState({
        checkboxState: {}
    });

    const refreshPage = () => {
        window.location.reload(false);
    }

    const closeTransferModel = (modalState, isRefersh) => {
        setBackgroundState(modalState);
        if (isRefersh) {
            refreshPage();
        }
    }

    async function onSubmit(platForm) {
        var attestationKey = [];
        switch (platForm) {
            case PLATFORM_TYPE.APIM:
                userInfo.current.groupInfoAPIM.forEach(g => {
                    apimObjListCheckboxState.checkboxState[g.UGGroupName].filter(o => o.IsSelected === true).forEach(x => {
                        attestationKey.push(x.AttestationKey);
                    });
                });
                break;
            case PLATFORM_TYPE.EVENT_STREAMING:
                userInfo.current.groupInfoEvenstreaming.forEach(g => {
                    esObjListCheckboxState.checkboxState[g.UGGroupName].filter(o => o.IsSelected === true).forEach(x => {
                        attestationKey.push(x.AttestationKey);
                    });
                });
                break;
            case PLATFORM_TYPE.MULESOFT:
                userInfo.current.groupInfoMulesoft.forEach(g => {
                    muleObjListsoftCheckboxState.checkboxState[g.UGGroupName].filter(o => o.IsSelected === true).forEach(x => {
                        attestationKey.push(x.AttestationKey);
                    });
                });
                break;
            case PLATFORM_TYPE.NATIVE_API:
                userInfo.current.groupInfoNativeAPI.forEach(g => {
                    nativeObjListCheckboxState.checkboxState[g.UGGroupName].filter(o => o.IsSelected === true).forEach(x => {
                        attestationKey.push(x.AttestationKey);
                    });
                });
                break;
            default:
                return;
        }
        var request = {
            Attestkey: attestationKey
        }
        try {
            const postRes = await userInfo.current.attestationAPIService.putSubmit(request);
            if (postRes.status === 200) {
                refreshPage();
            }
        } catch (err) {
            userInfo.current.errorMessage = err.message;
            setBackgroundState({
                errorMessage: `Submit failed: ${err.message}. Please contact support team via email "GSC-EMIT-AZURE-INTEGRATION-SUPPORT@exxonmobil.com".`,
                isLoading: false
            });
        }
    }

    useEffect(() => {
        async function init() {
            try {
                // Get microsoft graph access token and Initiate service.
                let acquireTokenRes = await authProvider.acquireTokenSilent(authenticationParameters);
                let msToken = acquireTokenRes.accessToken;
                let msAPIService = new MSAPIService(msToken);
                userInfo.current.msService = msAPIService;

                // Get attestation API access token  and Initiate service.
                let apiToken = await getMsalAuthToken();
                let attestationAPI = new AttestationAPIService(apiToken);
                userInfo.current.attestationAPIService = attestationAPI;

                // // Get user display name from MS Graph API.
                const accoutDetailsRes = await msAPIService.getUserName();
                userInfo.current.displayName = accoutDetailsRes.data.displayName;
                userInfo.current.email = accoutDetailsRes.data.mail;
                // Get group name (All platform) by Display name.
                try {
                    const groupName = await attestationAPI.getGroupName(userInfo.current.displayName, userInfo.current.email);
                    userInfo.current.groupInfoAPIM = groupName.data.filter(x => x.PlatformType.toLowerCase() === 'apim');
                    userInfo.current.groupInfoMulesoft = groupName.data.filter(x => x.PlatformType.toLowerCase() === 'mulesoft');
                    userInfo.current.groupInfoEvenstreaming = groupName.data.filter(x => x.PlatformType.toLowerCase() === 'eventstreaming');
                    userInfo.current.groupInfoNativeAPI = groupName.data.filter(x => x.PlatformType.toLowerCase() === 'native');
                } catch (err) {
                    throw err;
                }
                // Get object list.
                try {
                    userInfo.current.apimObjsByGroup = await getAPIMObjectList(attestationAPI);
                    userInfo.current.eventstreamingObjsByGroup = await getESObjectList(attestationAPI);
                    userInfo.current.muleSoftObjsByGroup = await getMulesoftObjectList(attestationAPI);
                    userInfo.current.nativeObjsByGroup = await getNativeObjectList(attestationAPI);
                    setUserInfo({
                        displayName: userInfo.current.displayName,
                        email: userInfo.current.email,
                        groupInfoAPIM: userInfo.current.groupInfoAPIM,
                        groupInfoMulesoft: userInfo.current.groupInfoMulesoft,
                        groupInfoEvenstreaming: userInfo.current.groupInfoEvenstreaming,
                        groupInfoNativeAPI: userInfo.current.groupInfoNativeAPI,
                        apimObjsByGroup: userInfo.current.apimObjsByGroup,
                        muleSoftObjsByGroup: userInfo.current.muleSoftObjsByGroup,
                        eventstreamingObjsByGroup: userInfo.current.eventstreamingObjsByGroup,
                        nativeObjsByGroup: userInfo.current.nativeObjsByGroup,
                    });
                    setApimObjListCheckboxState({
                        checkboxState: userInfo.current.apimObjsByGroup
                    });
                    setMuleObjListsoftCheckboxState({
                        checkboxState: userInfo.current.muleSoftObjsByGroup
                    });
                    setEsObjListCheckboxState({
                        checkboxState: userInfo.current.eventstreamingObjsByGroup
                    });
                    setNativeObjListCheckboxState({
                        checkboxState: userInfo.current.nativeObjsByGroup
                    });
                    setBackgroundState({
                        isLoading: false
                    });
                } catch (err) {
                    throw new Error(`Error: Cannot get object list (${err.message})`);
                }

            } catch (err) {
                if(err?.response?.status === 404) {
                    userInfo.current.isUserNotFound = true;
                    setBackgroundState({
                        isUserNotFound: true
                    });
                } else {
                    userInfo.current.errorMessage = err.message;
                    setBackgroundState({
                        errorMessage: err.message,
                        isLoading: false
                    });
                }
            }
        }
        init();
    }, []);

    function onTransferOwnershipClicked(platform) {
        switch (platform) {
            case PLATFORM_TYPE.APIM:
                setBackgroundState({ isShowAPIMTransferModal: true });
                break;
            case PLATFORM_TYPE.EVENT_STREAMING:
                setBackgroundState({ isShowESTransferModal: true });
                break;
            case PLATFORM_TYPE.MULESOFT:
                setBackgroundState({ isShowMulesoftTransferModal: true });
                break;
            case PLATFORM_TYPE.NATIVE_API:
                setBackgroundState({ isShowNativeTransferModal: true });
                break;
            default:
                return;
        }
    }

    async function submitTransfer(request) {
        const postRes = await userInfo.current.attestationAPIService.postTransfer(request);
        return postRes;
    }

    async function getAPIMObjectList(attestationAPI) {
        var objs = {}
        await Promise.all(userInfo.current.groupInfoAPIM.map(async g => {
            var objectList = await attestationAPI.getObjectList('apim', g.UGGroupName, userInfo.current.email);
            objs[g.UGGroupName] = objectList.data.map(x => {
                return {
                    UGGroupName: g.UGGroupName,
                    UGGroupEmail: g.UGGroupEmail,
                    UGGroupOwnerName: userInfo.current.displayName,
                    ObjectName: x.ObjectName,
                    PlatformType: x.PlatformType,
                    AttestationKey: x.AttestationKey,
                    IsSelected: true
                }
            });
        }));
        return objs;
    }

    async function getESObjectList(attestationAPI) {
        var objs = {}
        await Promise.all(userInfo.current.groupInfoEvenstreaming.map(async g => {
            var objectList = await attestationAPI.getObjectList('eventstreaming', g.UGGroupName, userInfo.current.email);
            objs[g.UGGroupName] = objectList.data.map(x => {
                return {
                    UGGroupName: g.UGGroupName,
                    UGGroupEmail: g.UGGroupEmail,
                    UGGroupOwnerName: userInfo.current.displayName,
                    ObjectName: x.ObjectName,
                    PlatformType: x.PlatformType,
                    AttestationKey: x.AttestationKey,
                    IsSelected: true
                }
            });
        }));
        return objs;
    }

    async function getMulesoftObjectList(attestationAPI) {
        var objs = {}
        await Promise.all(userInfo.current.groupInfoMulesoft.map(async g => {
            var objectList = await attestationAPI.getObjectList('mulesoft', g.UGGroupName, userInfo.current.email);
            objs[g.UGGroupName] = objectList.data.map(x => {
                return {
                    UGGroupName: g.UGGroupName,
                    UGGroupEmail: g.UGGroupEmail,
                    UGGroupOwnerName: userInfo.current.displayName,
                    ObjectName: x.ObjectName,
                    PlatformType: x.PlatformType,
                    AttestationKey: x.AttestationKey,
                    IsSelected: true
                }
            });
        }));
        return objs;
    }

    async function getNativeObjectList(attestationAPI) {
        var objs = {}
        await Promise.all(userInfo.current.groupInfoNativeAPI.map(async g => {
            var objectList = await attestationAPI.getObjectList('native', g.UGGroupName, userInfo.current.email);
            objs[g.UGGroupName] = objectList.data.map(x => {
                return {
                    UGGroupName: g.UGGroupName,
                    UGGroupEmail: g.UGGroupEmail,
                    UGGroupOwnerName: userInfo.current.displayName,
                    ObjectName: x.ObjectName,
                    PlatformType: x.PlatformType,
                    AttestationKey: x.AttestationKey,
                    IsSelected: true
                }
            });
        }));
        return objs;
    }

    return <div>
        {backgroundState.isUserNotFound ? (
            <Alert variant="success">Your request completed successfully. No further action required</Alert>
        ) : (
            <div>
                {backgroundState.errorMessage ? (
                    <Alert variant="error" >{backgroundState.errorMessage}</Alert>
                ) : (
                    < section >
                        {backgroundState.isLoading ? (
                            <ProgressBar indeterminate hideValueLabel />
                        ) : (
                            <div>
                                <Tabs>
                                    {userInfoState.groupInfoAPIM.length > 0 && <Tabs.Item title="APIM">
                                        <p>
                                            By selecting the objects below, you have acknowledged your
                                            responsibilities as the owner of the API(s) as documented in this{" "}
                                            <a href="https://appwiki.xom.cloud/docs/APIs/NewAPIMSharedServices-APIOwnerResponsibilities.html" style={{ color: "red" }}>
                                                <b>link.</b>
                                            </a>
                                        </p>
                                        <ObjectCkboxList
                                            groupInfo={userInfoState.groupInfoAPIM}
                                            objectList={apimObjListCheckboxState.checkboxState}
                                            setUserState={setEsObjListCheckboxState}
                                            source="apim_tab"
                                        />
                                        <Button.Group>
                                            <Button
                                                className="em-u-margin-half"
                                                variant="primary"
                                                onClick={() => {
                                                    onSubmit(PLATFORM_TYPE.APIM);
                                                }}
                                            >
                                                Submit
                                            </Button>
                                            <Button className="em-u-margin-half" variant="primary" color="caution" onClick={() => onTransferOwnershipClicked(PLATFORM_TYPE.APIM)}>Transfer Ownership</Button>
                                        </Button.Group>
                                        <ApimTransferModal
                                            msService={userInfo.current.msService}
                                            isShow={backgroundState.isShowAPIMTransferModal}
                                            closeModalCallBack={closeTransferModel}
                                            submitTransfer={submitTransfer}
                                            groupInfo={userInfoState.groupInfoAPIM}
                                            objectList={apimObjListCheckboxState.checkboxState}
                                            setUserState={setApimObjListCheckboxState}
                                        />
                                    </Tabs.Item>}
                                    {userInfoState.groupInfoEvenstreaming.length > 0 && <Tabs.Item title="Event Streaming">
                                        <p>
                                            By selecting the objects below, you have acknowledged your
                                            responsibilities as the owner of the API(s) as documented in this{" "}
                                            <a href="https://appwiki.xom.cloud/docs/Integration/EventStreaming/evts-owner-responsibility.html" style={{ color: "red" }}>
                                                <b>link.</b>
                                            </a>
                                        </p>
                                        <ObjectCkboxList
                                            groupInfo={userInfoState.groupInfoEvenstreaming}
                                            objectList={esObjListCheckboxState.checkboxState}
                                            setUserState={setEsObjListCheckboxState}
                                            source="es_tab"
                                        />
                                        <Button.Group>
                                            <Button
                                                className="em-u-margin-half"
                                                variant="primary"
                                                onClick={() => {
                                                    onSubmit(PLATFORM_TYPE.EVENT_STREAMING)
                                                }}
                                                active={location.pathname === "/submit"}
                                            >
                                                Submit
                                            </Button>
                                            <Button className="em-u-margin-half" variant="primary" color="caution" onClick={() => onTransferOwnershipClicked(PLATFORM_TYPE.EVENT_STREAMING)}>Transfer Ownership</Button>
                                        </Button.Group>
                                        <ESTransferModal
                                            msService={userInfo.current.msService}
                                            isShow={backgroundState.isShowESTransferModal}
                                            closeModalCallBack={closeTransferModel}
                                            submitTransfer={submitTransfer}
                                            groupInfo={userInfoState.groupInfoEvenstreaming}
                                            objectList={esObjListCheckboxState.checkboxState}
                                            setUserState={setEsObjListCheckboxState}
                                        />
                                    </Tabs.Item>}
                                    {userInfoState.groupInfoMulesoft.length > 0 && <Tabs.Item title="MuleSoft">
                                        <p>
                                            By selecting the objects below, you have acknowledged your
                                            responsibilities as the owner of the API(s) as documented in this{" "}
                                            <a href="https://appwiki.xom.cloud/docs/APIs/APIGroupOwnerResponsibilities.html" style={{ color: "red" }}>
                                                <b>link.</b>
                                            </a>
                                        </p>
                                        <ObjectCkboxList
                                            groupInfo={userInfoState.groupInfoMulesoft}
                                            objectList={muleObjListsoftCheckboxState.checkboxState}
                                            setUserState={setMuleObjListsoftCheckboxState}
                                            source="mulesoft_tab"
                                        />
                                        <Button.Group>
                                            <Button
                                                className="em-u-margin-half"
                                                variant="primary"
                                                onClick={() => {
                                                    onSubmit(PLATFORM_TYPE.MULESOFT);
                                                }}
                                                active={location.pathname === "/submit"}
                                            >
                                                Submit
                                            </Button>
                                            <Button className="em-u-margin-half" variant="primary" color="caution" onClick={() => onTransferOwnershipClicked(PLATFORM_TYPE.MULESOFT)}>Transfer Ownership</Button>
                                        </Button.Group>
                                        <MulesoftTransferModal
                                            msService={userInfo.current.msService}
                                            isShow={backgroundState.isShowMulesoftTransferModal}
                                            closeModalCallBack={closeTransferModel}
                                            submitTransfer={submitTransfer}
                                            groupInfo={userInfoState.groupInfoMulesoft}
                                            objectList={muleObjListsoftCheckboxState.checkboxState}
                                            setUserState={setMuleObjListsoftCheckboxState}
                                        />
                                    </Tabs.Item>}
                                    {userInfoState.groupInfoNativeAPI.length > 0 && <Tabs.Item title="Native API">
                                        <p>
                                            By selecting the objects below, you have acknowledged your
                                            responsibilities as the owner of the API(s) as documented in this{" "}
                                            <a href="https://appwiki.xom.cloud/docs/APIs/Native-API-Platform/Native-RolesAndResponsibilities.html" style={{ color: "red" }}>
                                                <b>link.</b>
                                            </a>
                                        </p>
                                        <ObjectCkboxList
                                            groupInfo={userInfoState.groupInfoNativeAPI}
                                            objectList={nativeObjListCheckboxState.checkboxState}
                                            setUserState={setMuleObjListsoftCheckboxState}
                                            source="native_tab"
                                        />
                                        <Button.Group>
                                            <Button
                                                className="em-u-margin-half"
                                                variant="primary"
                                                onClick={() => {
                                                    onSubmit(PLATFORM_TYPE.NATIVE_API);
                                                }}
                                                active={location.pathname === "/submit"}
                                            >
                                                Submit
                                            </Button>
                                            <Button className="em-u-margin-half" variant="primary" color="caution" onClick={() => onTransferOwnershipClicked(PLATFORM_TYPE.NATIVE_API)}>Transfer Ownership</Button>
                                        </Button.Group>
                                        <NativeTransferModal
                                            msService={userInfo.current.msService}
                                            isShow={backgroundState.isShowNativeTransferModal}
                                            closeModalCallBack={closeTransferModel}
                                            submitTransfer={submitTransfer}
                                            groupInfo={userInfoState.groupInfoNativeAPI}
                                            objectList={nativeObjListCheckboxState.checkboxState}
                                            setUserState={setNativeObjListCheckboxState}
                                        />
                                    </Tabs.Item>}
                                </Tabs>
                            </div>
                        )}
                    </section >
                )}
            </div>
        )}


    </div>
}