import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: "https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
  },
  cache: {
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
export const authenticationParameters = {
  scopes: [
    'User.Read',
    // 'api://87159894-3f7c-4078-b6fb-7dab074d4e5e',
  ],
  forceRefresh: false 
}
 
// Options
const options = {
  loginType: LoginType.Redirect
  // tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)