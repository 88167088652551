import { React, useState } from "react";
import {
    Checkbox,
    Section
} from "@exxonmobil/react-unity";

export default function ObjectCkboxList({ groupInfo, objectList, name, setUserState, source }) {
    const [state, setState] = useState({
        localObjList: objectList
    });

    function handleChange(ugName, objName, checked) {
        const target = state.localObjList[ugName];
        const updatedTarget = target.map(x => {
            if (x.ObjectName == objName) {
                x.IsSelected = checked;
            }
            return x;
        });
        const newState = state.localObjList;
        newState[ugName] = updatedTarget;
        setState({
            localObjList: newState
        });
        setUserState({
            checkboxState: newState
        });
    }

    function getCheckbox(ugName) {
        let list = [];
        try {
            const cObjList = state.localObjList[ugName];
            cObjList.forEach(item => {
                list.push(<Checkbox
                    value={item.ObjectName}
                    label={item.ObjectName}
                    id={`${source}_check_${ugName}_${item.ObjectName}`}
                    checked={item.IsSelected}
                    onChange={(checked) => handleChange(ugName, item.ObjectName, checked)}
                    key={`${source}_check_${ugName}_${item.ObjectName}_key`}
                    className=""
                />);
            });
        } catch {

        }
        return list;
    }
    function getList() {
        const sections = [];
        groupInfo.forEach(g => {
            sections.push(
                <Section title={g.UGGroupName} variant="expandable" open={true} key={`${source}_section_checkboxlist_key`} underline="main">
                    {state.localObjList[g.UGGroupName].length > 0 ?
                        (<Checkbox.Group>
                            {getCheckbox(g.UGGroupName, g.PlatformType)}
                        </Checkbox.Group>) :
                        (<p>All API(s) under this UG were attested.</p>)
                    }

                </Section>)
        })
        return sections;
    }

    return (<Checkbox.Group label={`Below are the list of ${name} listed under your ownership :`}>
        {getList()}
    </Checkbox.Group>)
}