import { msAxios } from "../axios_microsoft";

export class MSAPIService {
    constructor(jwtAccessToken) {
        if (
            jwtAccessToken === null ||
            jwtAccessToken === undefined ||
            jwtAccessToken === ""
        ) {
            throw new Error("jwtAccessToken must have a value");
        }
        this.jwtAccessToken = jwtAccessToken;
        msAxios.defaults.headers.Authorization = `Bearer ${this.jwtAccessToken}`;
    }

    getUserName() {
        return msAxios.get("/me");
    }

    getPeopleList(keyword) {
        return msAxios.get(`/users?$filter=startswith(givenName, '${keyword}') or startswith(surname, '${keyword}') or startswith(displayName, '${keyword}')`);
    }

    getUGGrouplist(id) {
        return msAxios.get(`/users/${id}/memberOf`);        
    } 
}