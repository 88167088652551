import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import "@exxonmobil/react-unity/dist/unity.css";
import { authProvider } from "./authProvider";
import { AzureAD } from "react-aad-msal";

/**
 *
 * This is the main definition of your application.
 *
 * For more information about the BrowserRouter being used, see: https://reactrouter.com/web/api
 *
 */
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
  <AzureAD provider={authProvider} forceLogin={true}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AzureAD>
</React.StrictMode>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
