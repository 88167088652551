import {authProvider} from "./authProvider";

export const getMsalAuthToken = () => {
    // https:azuread.github.io/microsoft-authentication-library-for-js/docs/msal/#:~:text=In%20MSAL%2C%20you%20can%20get,to%20obtain%20an%20access%20token
    const tokenRequest = {
      scopes: ["openid", "87159894-3f7c-4078-b6fb-7dab074d4e5e/Files.Read"],
    };
    if (authProvider.getAccount()) {
      return authProvider
        .acquireTokenSilent(tokenRequest)
        .then((res) => {
          return res.accessToken;
        })
        .catch((err) => {
          if (err.name === "InteractionRequiredAuthError") {
            return authProvider
              .acquireTokenRedirect(tokenRequest)
              .then((res) => {
                return res.accessToken;
              })
              .catch((err) => {
                throw err;
              });
          }
          throw err;
        });
    }
    return authProvider
      .acquireTokenRedirect(tokenRequest)
      .then((res) => {
        return res.accessToken;
      })
      .catch((err) => {
        if (err.name === "InteractionRequiredAuthError") {
          return authProvider
            .acquireTokenRedirect(tokenRequest)
            .then((res) => {
              return res.accessToken;
            })
            .catch((err) => {
              throw err;
            });
        }
        throw err;
      });
  };