import React  from "react";
import { Route, Switch } from "react-router-dom";
import Layout from 'components/Layout';
import Home from 'pages/Home';

const App = () => (
    <Layout>
        <Switch>
            <Route exact path="/" component={Home} />
        </Switch>
    </Layout>
);

export default App