import React, { useState } from "react";
import ObjectCkboxList from "components/ObjectCkboxList";
import {
    Alert,
    Button,
    Modal,
    Checkbox,
    PeoplePicker,
    Card,
    MediaBlock,
    ProgressTracker,
    Loader
} from "@exxonmobil/react-unity";

export default function ApimTransferModal({ msService, isShow, closeModalCallBack, submitTransfer, groupInfo, objectList }) {
    const [checkedA, setCheckedA] = useState(false);
    const [checkedB, setCheckedB] = useState(false);
    const [currentProgress, setCurrentProgress] = useState(1);
    const [text, setText] = React.useState("");
    const [selectedPeople, setSelectedPeople] = React.useState(null);
    // const [selectedUGGroup, setSelectedUGGroup] = React.useState(null);
    const [people, updatePeopleList] = React.useState([]);
    // const [ugGroups, updateUGGroupList] = React.useState([]);
    const [objListCheckboxState, setObjListCheckboxState] = useState({
        checkboxState: objectList
    });

    const [backgroundState, setBackgroundState] = useState({
        isLoading: false,
        errorMessage: null,
    });

    function handleAddPerson(e, value) {
        setText(value.displayName);
        setSelectedPeople(value);
        // setSelectedUGGroup(null);
        // getUgGroupName(value.id);
    }

    // function handleSelectUGGroup(value) {
    //     const group = ugGroups.find(x => x.displayName === value)
    //     setSelectedUGGroup(group);
    // }

    async function onSearchPeopleChange(value) {
        setText(value);
        setSelectedPeople(null);
        // setSelectedUGGroup(null);
        let res = await msService.getPeopleList(value);
        updatePeopleList(res.data.value);
    }

    // async function getUgGroupName(id) {
    //     let res = await msService.getUGGrouplist(id);
    //     updateUGGroupList(res.data.value.sort((x, y) => {
    //         if (x.displayName < y.displayName) {
    //             return -1;
    //         }

    //         if (x.displayName > y.displayName) {
    //             return 1;
    //         }

    //         return 0;
    //     }));
    // }

    async function onSubmitTransfer() {
        setBackgroundState({ isLoading: true });
        var objToTransafer = [];
        groupInfo.forEach(g => {
            objListCheckboxState.checkboxState[g.UGGroupName].filter(o => o.IsSelected === true).forEach(x => {
                objToTransafer.push(x);
            });            
        });
        const originUGGroupName = objToTransafer[0]?.UGGroupName;
        const originUGGroupEmail = objToTransafer[0]?.UGGroupEmail;
        const request = {
            platform: "apim",
            // newUGGroupName: selectedUGGroup.displayName,
            // newUGGroupEmail: selectedUGGroup.mail,
            newUGGroupName: originUGGroupName,
            newUGGroupEmail: originUGGroupEmail,
            newUGOwnerName: selectedPeople.displayName,
            newUGOwnerEmail: selectedPeople.mail,
            objectToTransfer: objToTransafer
        }
        try {
            const transferResult = await submitTransfer(request);
            if (transferResult.status === 200) {
                setBackgroundState({ isLoading: false });
                closeModalCallBack(getModalCloseState(), true);
            } else {
                throw new Error(`Cannot transafer ownership (Status code: ${transferResult.status})`);
            }
        } catch (err) {
            setBackgroundState({
                isLoading: false,
                errorMessage: `Failed: ${err.message}`
            });
        }
    }

    function getToBeTransferredList() {
        var list = [];
        groupInfo.forEach(g => {
            var dd = objListCheckboxState.checkboxState[g.UGGroupName];
            dd.map(x => {
                if (x.IsSelected == true) {
                    list.push(x);
                }
            })
        })
        return list;
    }


    const getModalCloseState = () => {
        return { isShowAPIMTransferModal: false };
    }

    return <div>
        <Modal show={isShow} onHide={() => { closeModalCallBack(getModalCloseState(), false) }}>
            <Modal.Window>
                <Modal.Window.Header>
                    <Modal.Window.Header.Title>
                        APIM owner transfer
                    </Modal.Window.Header.Title>
                    <Modal.Window.Header.CloseButton onClick={() => closeModalCallBack(getModalCloseState(), false)}>
                        Close
                    </Modal.Window.Header.CloseButton>
                </Modal.Window.Header>
                <Modal.Window.Body>

                    <ProgressTracker>
                        <ProgressTracker.Item
                            label="Prerequisite Checklist"
                            complete={currentProgress > 1}
                            active={currentProgress === 1}>1</ProgressTracker.Item>
                        <ProgressTracker.Item
                            label="API to be transferred"
                            complete={currentProgress > 2}
                            active={currentProgress === 2}>2</ProgressTracker.Item>
                        <ProgressTracker.Item
                            label="New Owner"
                            complete={currentProgress > 3}
                            active={currentProgress === 3}>3</ProgressTracker.Item>
                        <ProgressTracker.Item
                            label="Complete"
                            active={currentProgress === 4}>4</ProgressTracker.Item>
                    </ProgressTracker>
                    {currentProgress == 1 && <div>
                        <p></p>
                        <p>You're about to transfer API(s) to new onwer!</p>
                        <p><strong>Please ensure you complete the following prerequisites.</strong></p>
                        <Checkbox.Group label={"Prerequisites:"}>

                            <Checkbox
                                value="1"
                                label="As the current owner, please ensure the Administrator UG group(s) are properly transferred to the successor. To complete ownership change, you can submit LAN Single Group Update request via IT Services or WebBar."
                                id={'transferCheckA' + '_apim'}
                                checked={checkedA}
                                onChange={(checked) => setCheckedA(checked)}
                            />
                            <Checkbox
                                value="2"
                                label={
                                    <>Please advise a new API owner to visit {<a class="em-c-btn__text" target="_blank" href="https://appwiki.xom.cloud/docs/APIs/NewAPIMSharedServices-APIOwnerResponsibilities.html">https://appwiki.xom.cloud/docs/APIs/NewAPIMSharedServices-APIOwnerResponsibilities.html</a>} where explains the expectations and responsibilities of API owners who use the Shared APIM. The new API owner should have a basic understanding of API owner responsibility, general concepts of on-premises API including the architecture of Shared APIM.</>
                                }
                                id={'transferCheckB' + '_apim'}
                                checked={checkedB}
                                onChange={(checked) => setCheckedB(checked)}
                            />
                        </Checkbox.Group>
                        {checkedA && checkedB ? (
                            <Button.Group>
                                <Button
                                    variant="primary"
                                    onClick={() => setCurrentProgress(2)}
                                >
                                    Next
                                </Button>
                            </Button.Group>
                        ) : (
                            <p><strong><i>Please check above prerequisites checkbox to go to next steps.</i></strong></p>
                        )}
                    </div>
                    }

                    {currentProgress == 2 && <div>
                        <p>
                            Select API(s) to be transfered.
                        </p>
                        <ObjectCkboxList
                            groupInfo={groupInfo}
                            objectList={objListCheckboxState.checkboxState}
                            name={"API(s)"}
                            url={"https://appwiki.xom.cloud/docs/APIs/NewAPIMSharedServices-APIOwnerResponsibilities.html"}
                            setUserState={setObjListCheckboxState}
                            source="apim_tf_modal"
                        />
                        <Button.Group>
                            <Button onClick={() => setCurrentProgress(1)}>Back</Button>
                            <Button
                                variant="primary"
                                onClick={() => setCurrentProgress(3)}
                            >
                                Next
                            </Button>
                        </Button.Group>
                    </div>
                    }

                    {currentProgress === 3 && <div>
                        {backgroundState.errorMessage && <Alert variant="error" >{backgroundState.errorMessage}</Alert>}
                        <form>
                            <PeoplePicker

                                onChange={handleAddPerson}
                                inputProps={{
                                    onChange: (e) => onSearchPeopleChange(e.target.value),
                                    value: text,
                                    placeholder: "Search",
                                    disabled: backgroundState.isLoading
                                }}
                                fieldProps={{
                                    label: "Transfer to:",
                                }}
                            >
                                {people
                                    .filter((p) => selectedPeople?.id != p.id)
                                    .filter(
                                        (p) =>
                                            p.displayName?.toLowerCase().includes(text.toLowerCase()) ||
                                            text.toLowerCase().includes(p.displayName?.toLowerCase())
                                    )
                                    .map((p) => (
                                        <React.Fragment>
                                            <PeoplePicker.Item value={p}>
                                                <PeoplePicker.Item.Suggestion>
                                                    <strong>{`${p.displayName} `}</strong>
                                                    <span>{`(${p.jobTitle})`}</span>
                                                </PeoplePicker.Item.Suggestion>
                                            </PeoplePicker.Item>
                                        </React.Fragment>
                                    ))}
                            </PeoplePicker>


                            {/* {selectedPeople && (
                                <Select
                                    disabled={backgroundState.isLoading}
                                    label="UG Group:"
                                    value={validValue}
                                    onChange={(e) => handleSelectUGGroup(e.target.value)}
                                >
                                    <option value={null}></option>
                                    {ugGroups.map(x => {
                                        if (x.displayName) {
                                            return <option value={x.displayName} selected={selectedUGGroup?.displayName == x.displayName}>{x.displayName}</option>
                                        }
                                        return;
                                    })}
                                </Select>
                            )
                            } */}
                            {selectedPeople && (
                                // {selectedPeople && selectedUGGroup && (
                                <div>
                                    <Card.List>
                                        <Card>
                                            <Card.Flag>Transferring</Card.Flag>
                                            <Card.Body>
                                                <MediaBlock size="small">
                                                    <ul>
                                                        {

                                                            getToBeTransferredList().map(x => {
                                                                return <li>{x.ObjectName}</li>
                                                            })
                                                        }
                                                    </ul>
                                                </MediaBlock>

                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Flag>To</Card.Flag>
                                            <Card.Body>
                                                <MediaBlock size="small">
                                                    {/* <MediaBlock.Media>
                                                    <ProfileImage src="https://unity.exxonmobil.com/images/sample/avatar-nick.png" />
                                                </MediaBlock.Media> */}
                                                    <MediaBlock.Body>
                                                        <MediaBlock.Body.Headline>{selectedPeople.displayName}</MediaBlock.Body.Headline>
                                                        <MediaBlock.Body.Description>{selectedPeople.jobTitle}</MediaBlock.Body.Description>
                                                        <p>
                                                            <strong>Email:</strong> {selectedPeople.mail} <br />
                                                            {/* <strong>UG Group:</strong> {selectedUGGroup.displayName} <br /> */}
                                                            {/* <strong>UG Group Email:</strong> {selectedUGGroup.mail ?
                                                                <span>{selectedUGGroup.mail}</span> : <span><strong style={{ color: "red" }}>empty</strong></span>}  */}
                                                            <br />
                                                        </p>

                                                    </MediaBlock.Body>
                                                </MediaBlock>
                                            </Card.Body>
                                        </Card>
                                    </Card.List>
                                </div>

                            )}
                            {backgroundState.isLoading === false ? (<Button.Group>
                                <Button onClick={() => setCurrentProgress(2)}>Back</Button>
                                {/* {selectedPeople?.displayName && selectedPeople?.mail && selectedUGGroup?.displayName && selectedUGGroup?.mail && */}
                                {selectedPeople?.displayName && selectedPeople?.mail &&
                                    <Button
                                        variant="primary"
                                        color="negative"
                                        onClick={() => onSubmitTransfer()}
                                    >
                                        Transfer
                                    </Button>
                                }
                            </Button.Group>) :
                                (<Loader className="em-u-margin loader" color="positive" />)}


                        </form>
                    </div>
                    }
                </Modal.Window.Body>
            </Modal.Window>
        </Modal >
    </div >
}