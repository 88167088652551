import React from "react";
import {
  HorizontalHeader,
  Button,
  SunIcon,
  MoonIcon,
} from "@exxonmobil/react-unity";
import { useHistory, useLocation } from "react-router-dom";

/**
 *
 * The main application header. To learn more about the Horizontal Header in React Unity,
 * visit: https://reactunity.azurewebsites.net/#/Headers%20and%20footers?id=horizontalheader
 *
 * For Vertical Headers, see: https://reactunity.azurewebsites.net/#/Headers%20and%20footers?id=verticalheader
 *
 * This example header comes prebuilt with a button to toggle darkmode. See src/App.js for the usage and implementation
 * of the ThemeProvider.
 *
 * This header also includes logic to navigate using react-router. For more, see: https://reactrouter.com/web/api
 *
 * @prop {boolean} inDarkMode - Keeps track of whether or not the app is in dark mode
 * @prop {function} onDarkModeChange - Takes a boolean that sets the dark mode status
 */

const Header = ({ inDarkMode, onDarkModeChange }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <HorizontalHeader title="Ownership Attestation" color="main">
      <HorizontalHeader.Nav>
        <a href="#home">
          {/* <HorizontalHeader.Nav.Item active>Home</HorizontalHeader.Nav.Item> */}
          <HorizontalHeader.Nav.Item
            onClick={() => history.push("/")}
            active={location.pathname === "/"}
          >
            Home
          </HorizontalHeader.Nav.Item>
        </a>
        <HorizontalHeader.Nav.Item>
          <Button
            variant="bare"
            onClick={() => onDarkModeChange((self) => !self)}
          >
            {inDarkMode ? <SunIcon /> : <MoonIcon />}
          </Button>
        </HorizontalHeader.Nav.Item>
      </HorizontalHeader.Nav>
    </HorizontalHeader>
  );
};

export default Header;
