import { attestationAxios } from "../axios_api";

export class AttestationAPIService {

    constructor(jwtAccessToken) {
        if (
            jwtAccessToken === null ||
            jwtAccessToken === undefined ||
            jwtAccessToken === ""
        ) {
            throw new Error("jwtAccessToken must have a value");
        }
        this.jwtAccessToken = jwtAccessToken;
        attestationAxios.defaults.headers.Authorization = `Bearer ${this.jwtAccessToken}`;
    }    

    getGroupName(nameuser, useremail) {
        nameuser = encodeURI(nameuser);
        return attestationAxios.get(`/findnamegroup?name=${nameuser}&email=${useremail}`);
    }

    getObjectList(platformType, groupName, personActionEmail) {
        return attestationAxios.get(`/objectsfromgroup/${platformType}/${groupName}/${personActionEmail}`);;
    }

    putSubmit(request) {
        return attestationAxios.put(`/submit`, request);
    }

    postTransfer(request) {
        return attestationAxios.post(`/transfer`, request);
    }

}